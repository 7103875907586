import React, { useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@headlessui/react";
import {
  faCloudSun,
  faThermometerHalf,
  faThermometerEmpty,
  faThermometerFull,
  faThermometerThreeQuarters,
  faSeedling,
  faThermometerQuarter,
} from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import { Workbox, messageSW } from "workbox-window";

const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

interface weatherReport {
  dateTime: Date;
  temperature: string;
  sky: string;
  soil: string;
}

const thermoIcon = (temp: string) => {
  switch (temp) {
    case "Freezing":
    case "Bracing":
      return (
        <FontAwesomeIcon
          icon={faThermometerEmpty}
          className="text-indigo-600"
        />
      );
    case "Chilly":
    case "Cool":
      return (
        <FontAwesomeIcon
          icon={faThermometerQuarter}
          className="text-blue-300"
        />
      );
    case "Mild":
    case "Warm":
      return (
        <FontAwesomeIcon icon={faThermometerHalf} className="text-yellow-300" />
      );
    case "Balmy":
    case "Hot":
      return (
        <FontAwesomeIcon
          icon={faThermometerThreeQuarters}
          className="text-yellow-500"
        />
      );
    default:
      return (
        <FontAwesomeIcon icon={faThermometerFull} className="text-red-500" />
      );
  }
};

function App() {
  const [weatherReports, setWeatherReports] = React.useState<weatherReport[]>(
    []
  );

  const [temperatures, setTemperatures] = React.useState<string[]>([]);
  const [skies, setSkies] = React.useState<string[]>([]);
  const [soils, setSoils] = React.useState<string[]>([]);
  const [unsentReports, setUnsentReports] = React.useState(0);

  React.useEffect(() => {
    loadWeatherReports();
  }, [unsentReports]);

  React.useEffect(() => {
    const wb = new Workbox(swUrl);

    // wb.addEventListener("activated", (event) => {
    //   // `event.isUpdate` will be true if another version of the service
    //   // worker was controlling the page when this version was registered.
    //   if (!event.isUpdate) {
    //     console.log("Service worker activated for the first time!");

    //     // If your service worker is configured to precache assets, those
    //     // assets should all be available now.
    //   }
    // });

    wb.addEventListener("message", (event) => {
      if (event.data.type && event.data.type == "syncReport") {
        setUnsentReports(event.data.entries);
      }
    });

    // Register the service worker after event listeners have been added.
    wb.register();

    wb.messageSW({ type: "UPDATE_COUNT" });

    loadWeatherReports();
    axios
      .get<string[]>("/weatherReports/temps")
      .then((response) => {
        setTemperatures(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get<string[]>("/weatherReports/skies")
      .then((response) => {
        setSkies(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => {
        console.log(error);
      });
    axios.get<string[]>("/weatherReports/soils").then((response) => {
      setSoils(response.data);
    });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<weatherReport>({});

  const loadWeatherReports = () => {
    axios
      .get<weatherReport[]>("/weatherReports")
      .then((response) => {
        setWeatherReports(response.data);
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  const onSubmit = (x: weatherReport) => {
    x.dateTime = new Date();
    axios
      .post("/weatherReports", x)
      .then((x) => {
        reset();
        // reload results
        loadWeatherReports();
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js

          reset();
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const f = new Intl.DateTimeFormat("en");

  return (
    <div className="p-8">
      {unsentReports !== 0 && (
        <div className="p-4 mb-4 bg-red-100 ring-2 ring-red-300 shadow rounded-sm">
          <span>
            {unsentReports} report{unsentReports === 1 ? " was" : "s were"}{" "}
            submitted offline. {unsentReports === 1 ? "It" : "They"} will be
            synced when you are next online.
          </span>
        </div>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="rounded-md shadow bg-green-50 bg-rays bg-cover bg-top"
      >
        <div className="grid grid-cols-3 space-x-4 p-4 bg-green-100">
          <div className="flex flex-col">
            <select
              {...register("temperature", { required: true })}
              defaultValue=""
              className="px-2 py-2 text-sm rounded-full shadow-inner focus:outline-none focus:ring-4 focus:ring-purple-500 focus:ring-opacity-50"
            >
              <option className="text-gray-300" value="" disabled>
                Temperature
              </option>
              {temperatures.map((x) => (
                <option value={x} key={x}>
                  {x}
                </option>
              ))}
            </select>
            <div className="text-red-600">
              <ErrorMessage
                errors={errors}
                name="temperature"
                message="required"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <select
              {...register("sky", { required: true })}
              defaultValue=""
              className="px-2 py-2 text-sm rounded-full shadow-inner focus:outline-none focus:ring-4 focus:ring-purple-500 focus:ring-opacity-50"
            >
              <option className="text-gray-300" value="" disabled>
                Sky
              </option>
              {skies.map((x) => (
                <option value={x} key={x}>
                  {x}
                </option>
              ))}
            </select>
            <div className="text-red-600">
              <ErrorMessage errors={errors} name="sky" message="required" />
            </div>
          </div>
          <div className="flex flex-col">
            <select
              {...register("soil", { required: true })}
              defaultValue=""
              className="px-2 py-2 text-sm rounded-full shadow-inner focus:outline-none focus:ring-4 focus:ring-purple-500 focus:ring-opacity-50"
            >
              <option className="text-gray-300" value="" disabled>
                Soil
              </option>
              {soils.map((x) => (
                <option value={x} key={x}>
                  {x}
                </option>
              ))}
            </select>
            <div className="text-red-600">
              <ErrorMessage errors={errors} name="soil" message="required" />
            </div>
          </div>
        </div>

        <div className="p-4">
          <button
            type="submit"
            className="uppercase tracking-wider text-white mx-auto px-4 py-3 bg-purple-400 rounded-full focus:outline-none focus:ring-4 focus:ring-purple-500  focus:bg-purple-500 focus:ring-opacity-50 shadow hover:shadow-md hover:bg-purple-300"
          >
            Submit a Weather Report!
          </button>
        </div>
      </form>

      <h1 className="font-bold font-sans text-xl pt-8 pb-4">
        Weather Reports:
      </h1>
      <div className="overflow-y-auto flex flex-col space-y-4">
        {weatherReports.map((x) => (
          <Transition
            key={x.dateTime.toString()}
            show={true}
            appear={true}
            enter="transform transition duration-"
            enterFrom="opacity-0 scale-0"
            enterTo="opacity-100 scale-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-yellow-50 rounded-md shadow">
              <div className="p-2 bg-yellow-200">
                {new Date(x.dateTime).toLocaleString("en-NZ")}
              </div>
              <div className="grid grid-cols-3 p-2">
                <div>
                  {thermoIcon(x.temperature)} {x.temperature}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCloudSun}
                    className="text-blue-400"
                  />{" "}
                  {x.sky}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSeedling}
                    className="text-green-600"
                  />{" "}
                  {x.soil}
                </div>
              </div>
            </div>
          </Transition>
        ))}
      </div>
    </div>
  );
}

export default App;
